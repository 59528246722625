import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ProjectAdd = lazy(() => import('../../container/project/projectAdd'));
const ProjectDetail = lazy(() => import('../../container/project/projectDetail'));
const SurveyDetail = lazy(() => import('../../container/survey/surveyDetail'));
const SurveyAdd = lazy(() => import('../../container/survey/surveyAdd'));

const Project = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route path="/create" element={<ProjectAdd />} />
        <Route path="/:projectId/survey-add" element={<SurveyAdd />} />
        <Route path="/:projectId/survey-detail/:surveyId/*" element={<SurveyDetail />} />
        <Route path="/:id/*" element={<ProjectDetail />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default Project;
